<template>
    <div class="mt-n6">
        <div style="width: 100%">
            <v-carousel v-if="todayNews.length > 0" :show-arrows="false" show-arrows-on-hover hide-delimiter-background :height="carouselHeight">
                <v-carousel-item v-for="i in todayNews" :key="i.id">
                    <div class="pt-0">
                        <v-row>
                            <v-col class="vertical-center" :style="{ height: (carouselHeight - 50) + 'px'}" cols="12" sm="6">
                                <div class="mx-auto" style="width: 80%">
                                    <v-row>
                                        <v-col class="yellow--text text--darken-3 text-left">
                                            <label class="font-weight-bold d-block pb-2" style="border-bottom: 1px solid;">
                                                ข่าวเด่นวันนี้
                                            </label>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <label class="d-block grey--text text--lighten-2 font-weight-bold">
                                                {{ convertShortContent(i.content, 400, true) }}
                                            </label>
                                            <label class="d-block grey--text mt-3 text-caption">
                                                {{ thaiDate(new Date(i.createdAt)) }}
                                            </label>
                                            <v-btn
                                                color="yellow darken-3"
                                                class="px-5 mt-3" 
                                                rounded
                                                @click="$router.push({ name: 'AnnouncementDetails', params: { id: i.id }})"
                                            >
                                                อ่านรายละเอียดเพิ่มเติม
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6" v-resize="onResize">
                                <v-img
                                    v-if="windowSize.x > 600"
                                    max-width="100%"
                                    :max-height="carouselHeight - 70"
                                    :src="i.image"
                                ></v-img>
                            </v-col>
                        </v-row>
                    </div>
                </v-carousel-item>
            </v-carousel>
        </div>
        <div class="text-center pb-16">
            <v-container>
                <v-row>
                    <v-col class="my-5">
                        <label class="yellow--text text--darken-3 font-weight-bold">ข่าวประชาสัมพันธ์</label>
                    </v-col>
                </v-row>
                <v-row class="text-left" v-for="a of announcements" :key="a.id">
                    <v-col cols="12" sm="4">
                        <div class="vertical-center">
                            <v-img
                                class="mr-md-3"
                                max-width="100%"
                                max-height="220px"
                                :src="a.image"
                            ></v-img>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <div class="vertical-center pa-3 mt-sm-0 mt-n8">
                            <div style="width: 100%">
                                <label class="yellow--text text--darken-3 line-text font-weight-bold pb-2 cursor-pointer"
                                    @click.prevent="$router.push({ name: 'AnnouncementDetails', params: { id: a.id }})">
                                    {{ a.topic }}
                                </label>
                                <label class="grey--text text--lighten-2 line-text">
                                    {{ a.content }}
                                </label>
                                <div class="d-flex justify-space-between mt-5">
                                    <label class="grey--text">
                                    {{ thaiDate(new Date(a.createdAt)) }}
                                    </label>
                                    <label>
                                        <a href="" @click.prevent="$router.push({ name: 'AnnouncementDetails', params: { id: a.id }})" class="yellow--text text--darken-3">อ่านเพิ่มเติม</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <!-- paging -->
                <v-row>
                    <v-col class="text-center py-5">
                        <v-pagination
                            color="yellow darken-3"
                            v-model="currentPage"
                            :length="1"
                            circle
                            dark
                        ></v-pagination>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <dialog-error
            ref="dialogError"
            :topic="'Error'"
            :text="errorMessage"
        />
    </div>
</template>

<script>
import axios from 'axios';
import DialogError from '../../components/DialogError.vue';
import { thaiDate } from '../../utils/text-mapping';

export default {
    data: () => ({
        carouselHeight: 450,
        currentPage: 1,
        announcements: [
            { id: '1' },
            { id: '2' },
            { id: '3' },
            { id: '4' },
            { id: '5' }
        ],
        errorMessage: '',
        todayNews: [],
        windowSize: {
            x: 0,
            y: 0
        },
        thaiDate
    }),
    methods: {
        convertShortContent(text, length, haveDot = false){
            const dot = haveDot ? '...' : '';
            return text && text.length >= length ? text.toString().substring(0, length) + dot : text;
        },
        onResize(){
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        }
    },
    async mounted(){
        try{
            const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents?isPublish=true');
            this.announcements = response.data;
            if(this.announcements.length > 0){
                this.todayNews = this.announcements.filter(a => a.isHighlight);
            }
        }catch(error){
            this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
            this.$refs.dialogError.show();
        }
    },
    components: {
        DialogError
    }
};
</script>

<style scoped>
.vertical-center{
    text-align: left;
    height: 220px;
    display: flex;
    align-items: center;
}

.line-text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.cursor-pointer{
    cursor: pointer;
}
</style>